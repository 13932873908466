const { find, isSet } = require("lodash");

const baseAjax = ({ url, type, data }, successCallback, errorCallback) => {
    $.ajax({
            url,
            type,
            data
        })
        .done((res) => {
            successCallback(res);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            errorCallback({ jqXHR, textStatus, errorThrown });
        });
}

function getCarts() {
    let url = '/get-carts';
    let type = 'get';
    let data = {};
    baseAjax({ url, type, data }, (res) => {
        // Array.prototype.getCarts = () => res;
        // $('.cart-count').html(res.length);
    }, (err) => {
        console.log(err);
    });
}

getCarts();

const productIds = []

$('.js--addToCart').on('click', function() {
    let product_id = $(this).attr('product-id');
    let quantity = $(this).closest('.add-to-cart-wrapper').find('.quantity').val();
    let size = $(this).closest('.add-to-cart-wrapper').find('.js--productSize').find(':selected').val();

    if (size == '') {
        let html = getHtmlForCartMessgae('size is required', 'error');
        $('.js--addToCartMessage').removeClass('d-none').html(html);
        return;
    }
    let url = '/add-to-cart';
    let type = 'GET';
    let data = { product_id, quantity, size };
    baseAjax({ url, type, data }, (res) => {
        product = res.product

        console.log(res)

        $('.empty-cart-message').remove()
        if ($('.test-data').val() === undefined) {
            console.log('instant')
            $('.instant-mini-cart').show()
            $html = $('#add-product-data').html()
            let productAlreadyCarted = false
            let diffentSizeProduct = false

            $.each(productIds, function(key, value) {
                if (value == product_id) {
                    if (size) {
                        $.each(res.cartDatas, function(index, cartData) {
                            if (product_id == cartData.product_id) {
                                if (size == cartData.product_attribute_id) {
                                    productAlreadyCarted = true
                                } else {
                                    diffentSizeProduct = true
                                }
                            }

                        })

                    } else {
                        productAlreadyCarted = true
                    }
                }
            })

            if (!productAlreadyCarted) {
                productIds.push(product_id)
                $('.mini-cart-product-list').append($('.instant-cart:first').clone())
                $.each(res.cart, function(key, cart) {
                    $len = $('.mini-cart-product-list').find('.instant-cart').length
                    console.log($len)

                    if ($len > 1) {
                        let productUrl = APP_URL + "/productdetails/" + product_id
                        let productDeleteUrl = APP_URL + "/minicart_delete/" + cart.id
                        let productImageUrl = APP_URL + "/images/backend_images/product/small/" + cart.product.image

                        $('.mini-cart-product-list').find('.product-name:last').html(cart.product.product_name)
                        if (size) {
                            $('.mini-cart-product-list').append('<input type="hidden" class="product-id unique-product product-id-' + cart.product.id + '-' + size + '" data-size="' + size + '" value="' + product_id + '" readonly>')
                            $('.mini-cart-product-list').find('.product-size:last').html('( ' + cart['size'] + ' )')
                            $('.mini-cart-product-list').find('.delete-product-minicart a:last').attr("data-size", cart.product_attribute_id)
                        } else {
                            $('.mini-cart-product-list').append('<input type="hidden" class="product-id unique-product product-id-' + cart.product.id + '" value="' + product_id + '" readonly>')
                        }

                        $('.mini-cart-product-list').find('.product-quantity:last').addClass('product-quantity-' + product_id + '-' + cart.product_attribute_id)
                        $('.mini-cart-product-list').find('.product-quantity:last').html(data.quantity)
                        $('.mini-cart-product-list:last').find('.product-price').html((cart.product.product_retail_discount))
                            // $('.mini-cart-product-list').find('.delete-product-minicart a:last').attr("href", productDeleteUrl)
                        $('.mini-cart-product-list').find('.delete-product-minicart a:last').attr("href", 'javascript:;')
                        $('.mini-cart-product-list').find('.delete-product-minicart a:last').attr("data-id", cart.id)
                            // $('.mini-cart-product-list').find('.delete-product-minicart a:last').attr('data-quantity', cart.quantity)
                        $('.mini-cart-product-list').find('.instant-cart:last').attr('id', 'cart-' + cart['id'] + '')

                    } else {
                        let productUrl = APP_URL + "/productdetails/" + product_id
                        let productDeleteUrl = APP_URL + "/minicart_delete/" + cart.id
                        let productImageUrl = APP_URL + "/images/backend_images/product/small/" + cart.product.image
                        if (size) {
                            $('.mini-cart-product-list').append('<input type="hidden" class="product-id product-id-' + cart.product.id + '-' + size + '" data-size="' + size + '" value="' + product_id + '" readonly>')
                            $('.mini-cart-product-list').find('.product-size:last').html('( ' + cart['size'] + ' )')
                            $('.delete-product-minicart a').attr("data-size", cart.product_attribute_id)
                        } else {
                            $('.mini-cart-product-list').append('<input type="hidden" class="product-id product-id-' + cart.product.id + '" value="' + product_id + '" readonly>')
                        }
                        $('.mini-cart-product-list').find('a').attr("href", productUrl)
                        $('.mini-cart-product-list').find('.product-name').html(cart.product.product_name)

                        $('.mini-cart-product-list').find('.product-quantity:last').addClass('product-quantity-' + product_id + '-' + cart.product_attribute_id)
                        $('.mini-cart-product-list').find('.product-quantity:last').html(data.quantity)
                            // $('.mini-cart-product-list').find('.product-price').html(cart.product.product_retail_discount)
                        $('.mini-cart-product-list').find('.product-price').html(cart.price)
                        $('.product-image1 a img').attr("src", productImageUrl)
                            // $('.delete-product-minicart a').attr("href", productDeleteUrl)
                        $('.delete-product-minicart a').attr("href", 'javascript:;')
                        $('.delete-product-minicart a').attr("data-id", cart.id)
                            // $('.delete-product-minicart a').attr('data-quantity', cart.quantity)
                        $('.mini-cart-product-list').find('.instant-cart').attr('id', 'cart-' + cart['id'] + '')
                    }

                })

                // $('.instant-mini-cart').find($('.total-cart-items')).html(parseInt($('.total-cart-items').html()) + parseInt(1))
            } else {
                let productLength = $('.unique-product').length
                if (productLength < 1) {
                    window.location.reload()
                }
                if (size) {
                    $previousQuantity = $('.product-quantity-' + product_id + '-' + size).html()
                    $('.instant-cart-product-detail').find('.product-quantity-' + product_id + '-' + size).html(parseInt(data.quantity) + parseInt($previousQuantity))
                } else {
                    $previousQuantity = $('.instant-cart-product-detail').find('.product-quantity').html()

                    $('.instant-cart-product-detail').find('.product-quantity').html(parseInt(data.quantity) + parseInt($previousQuantity))
                }

                // $('.non-instant-mini-cart').find('.product-quantity-' + product_id).html(parseInt(data.quantity) + parseInt($previousQuantity))
            }

            $totalProductLength = $('.instant-cart-product-detail').length
            $subTotal = 0
            $addToCartQuantity = 0
            if ($totalProductLength !== undefined && $totalProductLength > 0) {

                $('.instant-product-price').each(function(key) {
                    $price = $(this).text().replace(/,/g, '') | 0
                    $('.instant-mini-cart').find('.instant-product-quantity').each(function(productKey) {
                        if (key == productKey) {
                            $quantity = $(this).text().replace(/,/g, '') | 0
                            console.log('quanitity11:' + $quantity)
                            console.log('quanitity12:' + $addToCartQuantity)
                            $totalPrice = $quantity * $price
                            $subTotal += $totalPrice
                            $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
                        }
                    })
                })
            }

            $('.sub-total-instant').html($subTotal.toFixed(2))
            $('.instant-mini-cart').find($('.total-cart-items')).html($addToCartQuantity)
            $('.non-instant-mini-cart').find($('.total-cart-items1')).html($addToCartQuantity)

        } else {
            console.log('non-instant')
            $('.instant-mini-cart').remove()
            $html = $('.add-product-data').html()
            let productAlreadyCarted = false

            $('.product-ids').each(function(i, obj) {
                if ($(obj).val() == product_id) {
                    if (size) {
                        $.each(res.cartDatas, function(index, cartData) {
                            if (product_id == cartData.product_id) {
                                if (size == cartData.product_attribute_id) {
                                    productAlreadyCarted = true
                                }
                            }

                        })

                    } else {
                        productAlreadyCarted = true
                    }
                }

            });

            if (!productAlreadyCarted) {

                $('.mini-cart-product-list1').append($('#non-instant-cart-product-detail:first').clone())
                $('.mini-cart-product-list1').find('.cart--products-list-item').addClass('cart-product')
                $('.non-instant-cart-product-detail').append(' <input type="hidden" name="product-id" class="product-ids" value="' + product_id + '">')
                let $cartLengh = res.cart.length
                console.log($cartLengh - 1)
                $.each(res.cart, function(key, cart) {
                    console.log(key)
                    console.log(cart.product.image)
                    $len = $('.mini-cart-product-list').find('.instant-cart').length

                    let productUrl = APP_URL + "/productdetails/" + product_id
                    let productDeleteUrl = APP_URL + "/minicart_delete/" + cart.id
                    let productImageUrl = APP_URL + "/images/backend_images/product/small/" + cart.product.image

                    $('.non-instant-mini-cart').find('.product-id:last').val(product_id)
                    let firstProductId = $('.non-instant-mini-cart').find('.product-id:first').val()

                    if (key == $cartLengh - 1) {
                        if (size) {
                            console.log('remove quantity:' + 'product-quantity-' + firstProductId + '-' + cart.product_attribute_id)
                            $('.non-instant-mini-cart').append('<input type="hidden" class="product-id unique-product product-id-' + cart.product.id + '-' + size + '" data-size="' + size + '" value="' + product_id + '" readonly>')
                            $('.non-instant-mini-cart').find('.product-quantity:last').removeClass('product-quantity-' + firstProductId + '-' + cart.product_attribute_id)
                            $('.non-instant-mini-cart').find('.product-quantity:last').addClass('product-quantity-' + product_id + '-' + cart.product_attribute_id)
                            $('.non-instant-mini-cart').find('.product-size:last').show().html('( ' + cart['size'] + ' )')
                            $('.non-instant-mini-cart').find('.delete-product-minicart a:last').attr("href", productDeleteUrl)
                            $('.non-instant-mini-cart').find('.delete-product-minicart a:last').attr("data-size", cart.product_attribute_id)
                        } else {
                            $('.non-instant-mini-cart').append('<input type="hidden" class="product-id unique-product product-id-' + cart.product.id + '" value="' + product_id + '" readonly>')
                            $('.non-instant-mini-cart').find('.product-quantity:last').removeClass('product-quantity-' + firstProductId)

                            $('.non-instant-mini-cart').find('.product-quantity:last').addClass('product-quantity-' + product_id)
                            $('.non-instant-mini-cart').find('.product-size:last').hide()
                        }
                    }

                    $('.non-instant-mini-cart').find('.product-quantity:last').html(data.quantity)

                    $('.non-instant-mini-cart').find('a:last').attr("href", productUrl)
                    $('.non-instant-mini-cart').find('.product-name:last').html(cart.product.product_name)

                    // $('.non-instant-mini-cart').find('.product-price:last').html(cart.product.product_retail_discount)
                    $('.non-instant-mini-cart').find('.product-price:last').html(cart.price)
                    $('div.product-image a img:last').attr("src", productImageUrl)
                    $('.non-instant-mini-cart').find('.delete-product-minicart a:last').attr("href", 'javascript:;')

                    $('.non-instant-mini-cart').find('.delete-product-minicart a:last').attr("data-id", cart.id)
                    $('.non-instant-mini-cart').find('.cart-product:last').attr('id', 'cart-' + cart['id'] + '')

                })
            } else {

                if (size) {
                    $('#cart-' + res.cart.id).find('.product-price').html(res.cart.price)
                    $previousQuantity = $('.product-quantity-' + product_id + '-' + size).html()
                    console.log('preQuanitity:' + $previousQuantity)
                    console.log('.product-quantity-' + product_id + '-' + size)
                    $('.non-instant-mini-cart').find('.product-quantity-' + product_id + '-' + size).html(parseInt(data.quantity) + parseInt($previousQuantity))
                } else {
                    $('#cart-' + res.cart.id).find('.product-price').html(res.cart.price)
                        // $previousQuantity = $('.non-instant-mini-cart').find('.product-quantity-' + product_id +'-').html()
                    $previousQuantity = $('.non-instant-mini-cart').find('.product-quantity-' + product_id).html()
                    console.log('prev_quantity1 :' + $previousQuantity)
                        // $('.non-instant-mini-cart').find('.product-quantity-'+ product_id +'-').html(parseInt(data.quantity) + parseInt($previousQuantity))
                    $('.non-instant-mini-cart').find('.product-quantity-' + product_id).html(parseInt(data.quantity) + parseInt($previousQuantity))
                }
            }

            $totalProductLength = $('.non-instant-cart-product-detail').find('.cart-product').length

            if ($totalProductLength < 1) {
                window.location.reload()
            }
            $subTotal = 0
            $addToCartQuantity = 0
            if ($totalProductLength !== undefined && $totalProductLength > 0) {

                $('.non-instant-product-price').each(function(key) {
                    $price = $(this).text().replace(/,/g, '')
                    $('.non-instant-product-quantity').each(function(productKey) {
                        if (key == productKey) {
                            $quantity = $(this).text().replace(/,/g, '')
                            $totalPrice = $quantity * $price
                            $subTotal += $totalPrice
                            $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
                        }
                    })
                })
            }

            $('.sub-total-non-instant').html($subTotal.toFixed(2))
            $('.non-instant-mini-cart').find($('.total-cart-items1')).html($addToCartQuantity)
        }

        if ($('.cart-count').html() == undefined) {
            $('.cart').append('<span class="cart-count">' + data.quantity + '</span>');
        } else {
            let currentCount = parseInt($('.cart-count').html());
            $('.cart-count').html(parseInt(currentCount) + parseInt(data.quantity));
        }
        let html = getHtmlForCartMessgae(res.message, 'success');
        $('.js--addToCartMessage').removeClass('d-none').html(html);

    }, ({ jqXHR }) => {
        const message = jqXHR.responseJSON.message ? jqXHR.responseJSON.message : 'Please Log in to add to cart';
        let html = getHtmlForCartMessgae(message, 'error');
        $('.js--addToCartMessage').removeClass('d-none').html(html)
    });
});

// delete product from minicart
$(document).on('click', '.remove-cart', function() {
    let cart_id = $(this).attr('data-id'); //cart id 
    let quantity = $('#cart-' + cart_id).find('.product-quantity').html()
    let url = '/delete-instant-cart';
    let type = 'get';
    let data = {
        cart_id
    };

    if (!confirm("Are you sure you want to delete these item?")) {
        return true;
    }

    $.ajax({
            url,
            type,
            data
        })
        .done((res) => {
            console.log(res)
            $('#cart-' + res.id).remove()
            $('.non-instant-cart-product-detail').find('#cart-' + res.id).remove()
            if (res.size) {
                $('.product-id-' + res.product_id + '-' + res.product_attribute_id).remove()
            } else {
                $('.product-id-' + res.product_id).remove()
            }
            let currentCount = parseInt($('.cart-count').html());
            if (currentCount == 1) {
                $('.cart-count').remove();
            } else {
                $('.cart-count').html(currentCount - quantity);
            }
            $('.js--cartList').html(res);

            $totalProductLength = $('.non-instant-cart-product-detail').find('.cart-product').length
            if ($totalProductLength && $totalProductLength !== undefined) {
                $subTotal = 0
                $addToCartQuantity = 0
                if ($totalProductLength !== undefined && $totalProductLength > 0) {

                    $('.non-instant-product-price').each(function(key) {
                        $price = $(this).text().replace(/,/g, '')
                        $('.non-instant-product-quantity').each(function(productKey) {
                            if (key == productKey) {
                                $quantity = $(this).text().replace(/,/g, '')
                                $totalPrice = $quantity * $price
                                $subTotal += $totalPrice
                                $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
                            }
                        })
                    })
                }

                $('.sub-total-non-instant').html($subTotal.toFixed(2))
                $('.non-instant-mini-cart').find($('.total-cart-items1')).html($addToCartQuantity)
            } else {
                $('.sub-total-non-instant').html(0)
                $('.non-instant-mini-cart').find($('.total-cart-items1')).html(0)
            }

            $totalInstantProductList = $('.instant-cart-product-detail').length
            if ($totalInstantProductList && $totalInstantProductList !== undefined) {
                $subTotal = 0
                $addToCartQuantity = 0
                if ($totalInstantProductList !== undefined && $totalInstantProductList > 0) {
                    $('.instant-product-price').each(function(key) {
                        $price = $(this).text().replace(/,/g, '') | 0
                        $('.instant-mini-cart').find('.instant-product-quantity').each(function(productKey) {
                            if (key == productKey) {
                                $quantity = $(this).text().replace(/,/g, '') | 0
                                console.log('quanitity11:' + $quantity)
                                console.log('quanitity12:' + $addToCartQuantity)
                                $totalPrice = $quantity * $price
                                $subTotal += $totalPrice
                                $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
                            }
                        })
                    })
                }

                $('.sub-total-instant').html($subTotal.toFixed(2))
                $('.instant-mini-cart').find($('.total-cart-items')).html($addToCartQuantity)
            } else {
                $('.sub-total-instant').html(0)
                $('.instant-mini-cart').find($('.total-cart-items')).html(0)
            }

            $('input[name=product-id]').each(function() {
                console.log(res.product_id)
                if ($(this).val() == res.product_id) {
                    console.log('value:' + $(this).val())
                    if (res.product_attribute_id) {
                        window.location.reload();
                    }
                    $(this).remove();
                }
            });

        })
        .fail((jqXHR, textStatus, errorThrown) => {
            console.log(jqXHR);
        });
});

// $('span.product-quantity').on('click', function(e) {
//     $(this).attr('contentEditable', true);
// })
var debounceTimeout;

// $('.product-quantity').on('input', function(e) {

//     let that = this;
//     let $updateCartUrl = '/update-instant-cart';
//     clearTimeout(debounceTimeout);
//     debounceTimeout = setTimeout(function() {
//         console.log('cart debounce')
//               let cart_id = $(that).attr('cart-id');
//                 let quantity = $(that).html();
//                 console.log(quantity)
//                 // let url = '/update-cart';
//                 let url = $updateCartUrl;
//                 let type = 'get';
//                 let data = {
//                     cart_id,
//                     quantity
//                 };

//         //@TODO check why baseAjax call not working
//         console.log('call ajax')
//         $.ajax({
//             url,
//             type,
//             data
//         })
//         .done((res) => {
//             console.log(res)
//             $('.mini-cart-product-list1').find('#cart-'+ res.cart.id).find('.product-price').html(res.cart.price)
//             $('.mini-cart-product-list').find('#cart-'+ res.cart.id).find('.product-price').html(res.cart.price)

//             $totalProductLength = $('.non-instant-cart-product-detail').find('.cart-product').length
//             console.log('new:'+$totalProductLength)
//             if ($totalProductLength && $totalProductLength !== undefined) {
//                 $subTotal = 0
//                 $addToCartQuantity = 0
//                 if ($totalProductLength !== undefined && $totalProductLength > 0) {

//                     $('.non-instant-product-price').each(function(key) {
//                         $price = $(this).text().replace(/,/g, '')
//                         $('.non-instant-product-quantity').each(function(productKey) {
//                             if (key == productKey) {
//                                 $quantity = $(this).text().replace(/,/g, '')
//                                 $totalPrice = $quantity * $price
//                                 $subTotal += $totalPrice
//                                 $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
//                             }
//                         })
//                     })
//                 }

//                 $('.sub-total-non-instant').html($subTotal.toFixed(2))
//                 $('.non-instant-mini-cart').find($('.total-cart-items1')).html($addToCartQuantity)
//             }else {
//                 $('.sub-total-non-instant').html(0)
//                 $('.non-instant-mini-cart').find($('.total-cart-items1')).html(0)
//             }

//             $totalInstantProductList = $('.instant-cart-product-detail').length
//             console.log('new_se:'+$totalInstantProductList)
//             if ($totalInstantProductList  && $totalInstantProductList !== undefined) {
//                 $subTotal = 0
//                 $addToCartQuantity = 0
//                 if ($totalInstantProductList !== undefined && $totalInstantProductList > 0) {
//                     $('.instant-product-price').each(function(key) {
//                         $price = $(this).text().replace(/,/g, '') | 0
//                         $('.instant-mini-cart').find('.instant-product-quantity').each(function(productKey) {
//                             if (key == productKey) {
//                                 $quantity = $(this).text().replace(/,/g, '') | 0
//                                 console.log('quanitity11:' + $quantity)
//                                 console.log('quanitity12:' + $addToCartQuantity)
//                                 $totalPrice = $quantity * $price
//                                 $subTotal += $totalPrice
//                                 $addToCartQuantity = parseInt($addToCartQuantity) + parseInt($quantity)
//                             }
//                         })
//                     })
//                 }

//                 $('.sub-total-instant').html($subTotal.toFixed(2))
//                 $('.instant-mini-cart').find($('.total-cart-items')).html($addToCartQuantity)
//             }else {
//                 $('.sub-total-instant').html(0)
//                 $('.instant-mini-cart').find($('.total-cart-items')).html(0)
//             }

//         })
//         .fail((jqXHR, textStatus, errorThrown) => {
//             alert(jqXHR.responseJSON.message)
//         });
//     }, 300)


// })


$('.js--addToBuy').on('click', function() {
    let product_id = $(this).attr('product-id');
    let quantity = $(this).closest('.add-to-cart-wrapper').find('.quantity').val();
    let size = $(this).closest('.add-to-cart-wrapper').find('.js--productSize').find(':selected').val();
    console.log(size);
    if (size == '') {
        let html = getHtmlForCartMessgae('size is required', 'error');
        $('.js--addToCartMessage').removeClass('d-none').html(html);
        return;
    }
    let url = '/add-to-cart';
    let type = 'GET';
    let data = { product_id, quantity, size };
    baseAjax({ url, type, data }, (res) => {
        if ($('.cart-count').html() == undefined) {
            $('.cart').append(`<span class="cart-count">1</span>`);
        } else {
            let currentCount = parseInt($('.cart-count').html());
            $('.cart-count').html(currentCount + 1);
        }
        document.location.href = '/checkout';
    }, ({ jqXHR }) => {
        const message = jqXHR.responseJSON.message ? jqXHR.responseJSON.message : 'Please log in to buy'
        let html = getHtmlForCartMessgae(message, 'error');
        $('.js--addToCartMessage').removeClass('d-none').html(html)
    });
});

function getHtmlForCartMessgae(message, status) {
    let html = `<div class="col-lg-12">
                    <div class="alert ${status == 'success' ? 'bg-success text-white' : ' bg-danger text-white'} alert-dismissable">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <h5> <i class="icon fa fa-check"></i> Alert!</h5>
                        <h6>${message}</h6>
                    </div>
                </div>`

    return html;
}


$(document).on('click', '.js-pagination a', function(event) {
    event.preventDefault();
    let toFilter = {};
    $('#navbarTogglerDemo01').find('.js--filterWrapper').find('.js--searchCategory').each(function() {
        // console.log($(this));
        console.log('tet1')
        let key = $(this).find('.js--filterTitle').html();
        toFilter[key] = [];
        $(this).find('.js--filter').each(function() {
            if ($(this).is(':checked')) {
                toFilter[key].push($(this).val());
            }
        })
        toFilter['amount'] = [$('#min_price').val().replace(/,/g, ''), $('#max_price').val().replace(/,/g, '')]
    });

    $('li').removeClass('active');
    $(this).parent('li').addClass('active');
    var page = $(this).attr('href').split('page=')[1];

    let url = '/filter-products?page=' + page;
    let type = 'get';
    let category = getSearchParams('category');
    let data = { toFilter, category };
    getProductData(url, type, data)
});


$('.js--filter').on('change', $.debounce(700, function() {
    // alert('hey uo')
    let toFilter = {};
    $(this).closest('.js--filterWrapper').find('.js--searchCategory').each(function() {
        // console.log($(this));
        let key = $(this).find('.js--filterTitle').html();
        toFilter[key] = [];
        $(this).find('.js--filter').each(function() {
            if ($(this).is(':checked')) {
                toFilter[key].push($(this).val());
            }
        })
        toFilter['amount'] = [$('#min_price').val().replace(/,/g, ''), $('#max_price').val().replace(/,/g, '')]
    });
    // console.log(toFilter);
    let url = '/filter-products';
    let type = 'get';
    let category = getSearchParams('category');
    let data = { toFilter, category };

    getProductData(url, type, data)
}))

function getProductData(url, type, data) {
    baseAjax({ url, type, data }, (res) => {
        // @TODO functionality to update total
        // console.log(res);
        $('.js--products').html(res);
    }, (err) => {
        console.log(err);
    });
}


$('.js-filterPrice').on('change', function() {

    let toFilter = {};
    $(this).closest('.js--filterWrapper').find('.js--searchCategory').each(function() {
        // console.log($(this));
        let key = $(this).find('.js--filterTitle').html();
        toFilter[key] = [];
        $(this).find('.js--filter').each(function() {
            if ($(this).is(':checked')) {
                toFilter[key].push($(this).val());
            }
        })
        toFilter['amount'] = [$('#min_price').val().replace(/,/g, ''), $('#max_price').val().replace(/,/g, '')]
    });

    // toFilter.push($('#min_price').val())
    console.log(toFilter)
    let url = '/filter-products';
    let type = 'get';
    let category = getSearchParams('category');
    let data = { toFilter, category };
    baseAjax({ url, type, data }, (res) => {
        // @TODO functionality to update total
        console.log(res);
        $('.js--products').html(res);
    }, (err) => {
        console.log(err);
    });
})

$("#slider-range").slider({
    range: true,
    min: 0,
    max: parseFloat($('#max_price').val()) + 0.01,
    step: 0.01,
    values: [(parseFloat($('#min_price').val())), (parseFloat($('#max_price').val()) + 0.01)],
    slide: ($.debounce(700, function(event, ui) {
        $("#amount").val("NRs." + (parseFloat(ui.values[0])).toLocaleString() + " - NRs." + (parseFloat(ui.values[1]).toLocaleString()));
        $("#min_price").val(parseFloat(ui.values[0]).toLocaleString());
        $("#max_price").val(parseFloat(ui.values[1]).toLocaleString());
        $('.js-filterPrice').trigger('change')
    }))
});

if ($('#max_price').val() && $('#min_price').val() != '') {
    $("#amount").val("NRs." + $("#slider-range").slider("values", 0).toLocaleString() +
        " - NRs." + $("#slider-range").slider("values", 1).toLocaleString());
} else {
    $("#amount").val('Products not found');
}

function getSearchParams(k) {
    var p = {};
    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(s, k, v) { p[k] = v })
    return k ? p[k] : p;
}